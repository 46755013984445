import React from "react"
import { graphql } from "gatsby"
import { BlogCard } from "../../components/blogs"
import Layout from "../../components/layout"
import { map } from "lodash"
import { Container, Row } from "reactstrap"
import { PageBreadcrumb } from "../../components/shared"

export const query = graphql`
  query Category($slug: String!) {
    articles: allStrapiBlog(filter: { category: { slug: { eq: $slug } } }) {
      edges {
        node {
          slug
          title
          category {
            name
          }
          image {
            localFile {
              childImageSharp {
                fixed(width: 660) {
                  src
                }
              }
            }
          }
          content
          author {
            name
            picture {
              localFile {
                childImageSharp {
                  fixed(width: 30, height: 30) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
    category: strapiCategory(slug: { eq: $slug }) {
      name
    }
  }
`

const Category = ({ data }) => {
  const articles = data.articles.edges
  const category = data.category.name
  const seo = {
    metaTitle: category,
    metaDescription: `All ${category} articles`,
  }

  return (
    <Layout seo={seo}>
      <PageBreadcrumb
        page={{
          source: "blog",
          showBreadcrumb: true,
        }}
      >
        <h2>{category}</h2>
        <ul className="list-unstyled mt-4">
          <li className="list-inline-item h6 user text-white mr-2">
            {`Articles in ${category} category`}
          </li>
        </ul>
      </PageBreadcrumb>
      <section className="section">
        <Container>
          <Row>
            {map(articles, (post, post_idx) => {
              return (
                <BlogCard source={"blog"} post_idx={post_idx} post={post} />
              )
            })}
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default Category
